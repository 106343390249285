import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import Back from '../components/back/back';

import Glide from "@glidejs/glide"

const sliderConfig= {
  gap: 20,
  perView: 1,
  startAt: 0,
  type: "slider"
};

const RecipeBook = () => {
  useEffect(() => {
    const sliders = document.querySelectorAll('.glide');

    sliders.forEach(item => {
        new Glide(item, sliderConfig).mount()
    })
  }, [])

  return (
    <Layout>
      <div className="details case-study hc-template">
        <SEO title=".Recipebook Project" />
        <div className="fixed-nav desktop-only">
          <a href="/">Home</a>
        </div>
        <section className="wrapper-container">
          <div className="mobile-only">
            <a href="/">Home</a>
          </div>
          <h3>.Recipebook</h3>

          <p>
            A sample project within the cooking space. Acted as UI/UX designer for a recipe book application, working through phases such as problem definition, prototyping and testing, and high-fidelity designs. Utilized Figma, FigJam, and Google Docs.
          </p>
          <p><a rel="noopener noreferrer" href="https://www.figma.com/design/rYZ467LTF7w8O9wjnKyBUY/.Recipebook-for-Portfolio?node-id=1-2&t=fvipWlc5U2HyBH3T-1" title="Figma link" target="_blank">Figma link</a></p>

        </section>
        <section className="wrapper-container recipebook">
      
            <h3>Understanding and Empathizing with the User</h3>
            <p>
              <strong>The Problem</strong><br />
              Cooking from home, particularly from recipes, was chosen for the purpose of this project. To better understand how we might better the experience for users, secondary research and interviews were conducted.
            </p>
            <p>
            Key insights from secondary research, through competitive analysis and market research, showed:
            </p>
            
            <p>
              <ol className="numbered-list">
                <li>
                <strong>1. </strong>Websites for recipes were text-heavy
                </li>
                <li>
                <strong>2. </strong>Mobile applications require increased screen interaction due to available space.
                </li>
                <li>
                <strong>3. </strong>Lack of interactivity or features once on the recipe details.
                </li>
              </ol>
            </p>

            <p>
              <strong>Interviews</strong><br/>
              Interviews were utilized to better understand the problem space, potential features, and the user. Artifacts included: an affinity map, persona, and empathy map.
            </p>

            <p>
              <strong>Affinity Map</strong><br/>
              With the creation of an affinity map, three main insights and features were found.
            </p>

            <p>
              Users often searched for the same recipe or had multiple locations for having them saved, such as devices, browsers, and note-taking applications. With features related to recipe aggregation, a browser plugin, or mobile app overlay all being potential solutions.
            </p>

            <p>
              Users had difficulty finding specific parts for recipes or skimming through them. With features such as in-recipe search, voice-assistance, and focusable steps all being potential solutions. These features would have the added benefit of promoting accessibility within the application.
            </p>

            <p>
              Users each had their way of remembering recipes. Requiring a careful balance of customization and ease of use - with tagging recipes chosen as a compromise to complement search and create features.
            </p>

            <p className="text-center"><strong>Affinity Map</strong></p>

            
              <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/affinity-map_j8m0fh.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/affinity-map_j8m0fh.png" ></img></a>
            

            <p>
              <strong>Persona and Empathy Map</strong><br/>
              A combination of secondary research and interview findings was utilized to create a persona and empathy map to better empathize with the user. Key aspects that were emphasized included the diverse forms of media for cooking, ingredients utilization, and personal reasons for cooking and remembering recipes.
            </p>

            <p>
              <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395518/persona-and-empathy-map_dlmgb8.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395518/persona-and-empathy-map_dlmgb8.png" ></img></a>
            </p>
    
            <h3>Ideate</h3>

            <p>
            Although critical screens such as home and details screens would be designed, creating a new recipe was chosen to be a red route and the main feature to be designed and tested. Other features were, however, tested to guide more specific, in-depth, efforts.
            </p>

            <p className="text-center"><strong>User Flow</strong></p>

            
            <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/user-flow_lypmag.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/user-flow_lypmag.png" ></img></a>
            
            <p className="text-center"><strong>Wireframes</strong></p>

            <div className='glide mb-20 mt-20'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395489/mock-recipes-wf_mb3gt3.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395489/mock-recipes-wf_mb3gt3.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry-wf_kjre9e.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry-wf_kjre9e.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                   <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry-wf-1_uzdrwe.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry-wf-1_uzdrwe.png" ></img></a>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396471/chevron-left_io2o5z.png"></img></button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396472/chevron-right_ejgxv3.png"></img></button>
              </div>
            </div>
          
          
            <h3>Testing</h3>

            <p>
              Testing was done remotely with a medium-fidelity wireframe prototype. An important discovery made was the need for more context and information with regard to labels, placeholders, and auto/AI features. Another insight included the willingness of users for having more complexity in the details page while testing the search by pantry feature led to the discovery of users wanting a prompt-like approach rather than a conversational one.
            </p>
          
          
            <h3>High Fidelity Designs</h3>

            <p>
              <strong>Mood Board</strong><br/>
              The mood board utilized orange as its primary color while emphasizing sans serif font as the most prevalent font. Orange being chosen due to its relation to hunger and food while Inter being chosen as it would be a text-heavy application.
            </p>

            <p>
              The logo and name were inspired by the concept of being digital and also being a play on “dot com”.
            </p>

            <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395519/mood-board_ntb3qw.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395519/mood-board_ntb3qw.png" ></img></a>
            
            <p>
              <strong>Style Guide</strong><br/>
              The style guide drew from the mood board and expanded on font details, form interactions, and media specifications. 
            </p>

            <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/style-guide-1_niq7h6.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/style-guide-1_niq7h6.png" ></img></a>
            <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395519/style-guide-2_cl59is.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395519/style-guide-2_cl59is.png" ></img></a>
            
            <p className="text-center"><strong>High Fidelity Designs</strong></p>
           
            <div className='glide mb-20 mt-20'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-recipes_fazxov.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-recipes_fazxov.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry_yyeunu.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395490/mock-pantry_yyeunu.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                   <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-pantry-1_il6ckf.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-pantry-1_il6ckf.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-create-initial_flrhsy.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-create-initial_flrhsy.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                   <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-create-details_aliltw.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395491/mock-create-details_aliltw.png" ></img></a>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396471/chevron-left_io2o5z.png"></img></button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396472/chevron-right_ejgxv3.png"></img></button>
              </div>
            </div>

          <p>
            <strong>Next Steps</strong><br/>
            Next steps would include wireframes and testing pertaining to the finding, sorting, and filtering of recipes. While discovery could be started for a browser plugin and/or a mobile application that can be an overlay. Print considerations, similar to Google Photos, can also be made as printing is prevalent within the online cooking space.
          </p>
        </section>
        <div className="project-bottom">
          <Back></Back>
          <Link to="/fareshare" title="To Next Project">
            Next Project
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default RecipeBook;